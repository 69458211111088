import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { MM2_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';
import Testimonial from '@/components/Testimonial';
import NextProject from '@/components/Portfolio/NextProject';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import DoublePictures from '@/components/Portfolio/DoublePictures';

import testimonial from '@/referentials/testimonials/genievision';

export default function GenieVision({ data }) {
  const { t } = useTranslation();

  const tags = ['growth', 'prospection', 'linkedin', 'emailScraping', 'data'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverLmsg')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <DoublePictures
        pictures={[
          {
            source: data.notion,
            alt: 'Notion',
          },
          {
            source: data.scenarios,
            alt: 'Multicanal scenarios',
          },
        ]}
      />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />

      <Testimonial testimonial={testimonial()} />

      <NextProject title={t('nextProject')} to={MM2_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["genieVision", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/genie-vision/dashboard.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    notion: file(relativePath: { eq: "projects/genie-vision/notion.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    scenarios: file(
      relativePath: { eq: "projects/genie-vision/strat-multicanal.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
